@import "node_modules/@scottish-government/pattern-library/src/base/all-base";
@import "node_modules/@scottish-government/pattern-library/src/components/page-header/page-header";
@import "node_modules/@scottish-government/pattern-library/src/forms/error-summary/error-summary";

.ds_layout--article {
    grid-template-areas:
            'h h h h h h h h h h h h'
            'c c c c c c c c s s s s'
            'f f f f f f f f . . . .';
}

@include media-query(medium) {
    .ds_layout--article {
        grid-template-areas:
            'h h h h h h h h h h h h'
            'c c c c c c c c s s s s'
            'f f f f f f f f . . . .';
    }
}

@include media-query(large) {
    .ds_layout--article {
        grid-template-areas:
            'h h h h h h h h h h h h'
            'c c c c c c c c c c c c'
            'f f f f f f f f f . . .';
    }
}

.ds_layout__feedback {
    line-height: 24px;
    font-size: 16px;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-top: 24px;
    position: relative;
    p {
        margin: 0 0 16px;
    }
}

.ds_layout--article {
    clear: left;
    float: left;
    width: auto;
}

@include media-query(medium) {
    .ds_layout--article {

        .ds_layout__header-pc {
            clear: left;
            @include colwidth(13);
        }

        .ds_layout__content {
            @include colwidth(12);
        }

        .ds_layout__feedback {
            clear: left;
        }
    }
}

@include media-query(large) {
    .ds_layout--article {

        .ds_layout__header-pc {
            clear: left;
            @include colwidth(17);
        }

        .ds_layout__content {
            clear: left;
            @include colwidth(12);
            @include colpush(2);
        }

        .ds_layout__feedback {
            clear: left;
            @include colwidth(7);
            @include colpush(2);
        }
    }
}

html {
    min-height: 100%;
    position: relative;
}

.postcode-form {
    h3 {
        margin: 0;
    }
}

.ds_page {
    min-height: calc(100vh - 360px);
    margin-bottom: 360px;
}

.ds_site-footer {
    background: #0065bd;
    color: #fff;
    padding: 0 15px 30px;
    position: absolute;
    left: 0;
    right: 0;
}

.ds_layout__content {
    min-height: calc(100vh - 23em);
    padding: 0px 0px 20px 0px !important;
    .change-postcode {
        margin-bottom: 16px;
    }
}

.external-links__icon,
.external-links__icon--blue,
.external-links__icon--medium {
    background-image: url(assets/icons/svg/external-17-blue.svg) !important;
}

.external-links__icon--medium {
    width: 1.0625rem !important;
    height: 1.0625rem !important;
}
@media (min-width: 48.0625em) {
    a[target="_blank"] span.visually-hidden, .external-link-icon {
        width: 1.10526em;
        height: 1.10526em;
    }
}

a[target="_blank"] span.visually-hidden, .external-links__icon {
    margin-top: -.26em;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

@supports (display: grid) {
    .ds_page {
        display: grid;
        margin-bottom: 0;
        min-height: 100vh;
        grid-template: 'page-top' auto 'page-middle' 1fr 'page-bottom' auto;
    }
    .ds_page__top {
        grid-area: page-top;
    }
    .ds_page__middle {
        grid-area: page-middle;
    }
    .ds_page__bottom {
        grid-area: page-bottom;
    }

    .ds_site-footer {
        position: static;
    }

}

.ds_layout__feedback {
    position: relative;
    margin: 0 0 16px 0;
    clear: both;

}

.ds_layout__feedback::before {
    background-color: #eeeeee;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.scotgov-main-wrapper {
    min-height: calc(100vh - 40em);
    padding: 0px 0px 20px 0px !important;
}
