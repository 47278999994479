@import "node_modules/@scottish-government/pattern-library/src/base/all-base";
@import "node_modules/@scottish-government/pattern-library/src/components/site-footer/site-footer";

@media (min-width: 768px) {
  .ds_site-footer__gov-logo {
    float: right;
    margin-top: 0;
  }

  .ds_site-footer__content {
    display: grid;
    grid-gap: 0 32px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
        'a a a a a a a a a a a a'
        'b b b b b b b b . c c c';
  }
  .ds_site-footer__content > *[class] {
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }

  .ds_site-footer__site-items {
    grid-area: a;
  
  }

  .ds_site-footer__org {
    grid-area: c;
    justify-self: right;
    width: 100%;
    float: right;
  }

}

.ds_site-footer__site-items {
  border-bottom: 1px solid #b3b3b3;
  font-size: 14px;
  font-weight: 0;
  list-style-type: none;
  margin: 0 0 23px;
  padding-bottom: 16px;
}

.ds_site-items__item:not(:last-child) {
  margin-right: 20px;
}

.ds_site-items__item {
  display: inline-block;
  li {
    margin-bottom: 8px;
  }
}

.ds_site-footer__org-logo {
  display: block;
  position: relative;
  width: 165px;
  margin-top: 35px;
  height: 30px;
}

.ds_site-footer__copyright {
  padding-left: 0;
  color:#333333;
}

