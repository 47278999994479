@import "node_modules/@scottish-government/pattern-library/src/base/all-base";

.ds_site-branding {
  img {
    min-width: 228px;
    height: 77px;
    display: block;
  }

}
