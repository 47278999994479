@import "node_modules/@scottish-government/pattern-library/src/base/all-base";
@import "node_modules/@scottish-government/pattern-library/src/forms/form.base";
@import "node_modules/@scottish-government/pattern-library/src/forms/form.settings";
@import "node_modules/@scottish-government/pattern-library/src/forms/text-input/text-input";
@import "node_modules/@scottish-government/pattern-library/src/components/button/_button";
@import "/node_modules/@scottish-government/pattern-library/src/forms/select/_select";

.postcode-form {
  .split-change {
    display: flex;
    a {
      padding-left: 2em;
    }
  }
  color: #333333;
  background-color: $ds_colour__border--light;
  outline: 2px solid $ds_colour__border--light;
  padding: 1em;
  width: 100%;
  @include media-query(medium){
    width: 85%;
  }
}

.ds_select {
  background-color: white;
}
// // .ds_select-wrapper{
// //   position: relative;
// // }
.ds_button-pc {
  margin: 0;
}

.select-address-pc {
  margin: 0 0 16px 0;
}

.ds_select-arrow{
  &::before {
  border-right: transparent;  
  }
}