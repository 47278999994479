@import "node_modules/@scottish-government/pattern-library/src/base/helpers/_all";
@import "node_modules/@scottish-government/pattern-library/src/base/settings/_all"; 
@import "node_modules/@scottish-government/pattern-library/src/base/utilities/_all";
@import 'node_modules/@scottish-government/pattern-library/src/components/page-header/_page-header';

.ds-page-header {
    margin: 16px 0 32px;

    &__title {
        margin-bottom: 0;
    }

    &__metadata {
        margin-top: 8px;
    }
}

@include media-query(medium) {
    .ds-page-header {
        margin-top: 32px;

        &__label + &__title {
            margin-top: -8px;
        }
    }
}

@include media-query(medium) {
    .ds_layout--article {

        .ds_layout__header-pc {
            clear: left;
            @include colwidth(13);
        }
    }
}

@include media-query(large) {
    .ds_layout--article {

        .ds_layout__header-pc {
            clear: left;
            @include colwidth(17);
        }

        .ds_layout__content {
            clear: left;
            @include colwidth(12);
            @include colpush(2);
        }

        .ds_layout__feedback {
            clear: left;
            @include colwidth(7);
            @include colpush(2);
        }
    }
}